import React from "react";
import classNames from "classnames";

import { title } from "./Title.module.scss";

interface TitleProps extends React.HTMLAttributes<HTMLDivElement> {
  tagName?: keyof Pick<
    JSX.IntrinsicElements,
    "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span"
  >;
}

const Title: React.FC<TitleProps> = ({
  children,
  tagName: El = "h2",
  className,
  ...props
}) => {
  return (
    <El className={classNames(title, className)} {...props}>
      {children}
    </El>
  );
};

export { Title };
