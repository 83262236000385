import React, { useMemo, useState } from "react";
import { format } from "date-fns";

import { Page } from "../../Page";
import { ReactComponent as MapSvg } from "../../../images/svg/map.svg";
import { ReactComponent as Bubble1Svg } from "../../../images/svg/bubble1.svg";
import { ReactComponent as Bubble2Svg } from "../../../images/svg/bubble2.svg";
import { ReactComponent as Bubble3Svg } from "../../../images/svg/bubble3.svg";
import { ReactComponent as NextSvg } from "../../../images/svg/next.svg";
import MarkerImg from "../../../images/marker.png";

import {
  main,
  info,
  infoTitle,
  infoDescription,
  dates as datesCls,
  map,
  mapInner,
  bubble,
  bubbleInfo,
  box,
  boxLogo,
  boxItem,
  none,
} from "./Tour.module.scss";
import { Markdown } from "../../Markdown";

const BUBBLES = [<Bubble1Svg />, <Bubble2Svg />, <Bubble3Svg />];

interface TourPageProps {
  dates: Array<{
    datetime: number;
    end_datetime: number;
    venue: string;
    place: string;
    location: {
      type: string;
      coordinates: [number, number];
    };
    venue_google: string;
    position: {
      x: number;
      y: number;
    };
    description?: string;
  }>;
}

const TourPage: React.FC<TourPageProps> = ({ dates }) => {
  const [selected, setSelected] = useState(-1);

  const currentDates = useMemo(() => {
    const n = new Date().getTime();

    return dates
      .filter((d) => d.end_datetime > n)
      .map((d) => ({
        ...d,
        place: d.place.toUpperCase(),
        formattedDateTime: format(d.datetime, "do MMMM"),
        formattedEndDateTime: format(d.end_datetime, "do MMMM"),
      }));
  }, [dates]);

  const currentInfo = useMemo(() => {
    if (currentDates.length === 0) {
      return false;
    }

    if (selected < 0) {
      return currentDates[0];
    }

    return currentDates[selected];
  }, [currentDates, selected]);

  const duringDate = useMemo(() => {
    if (!currentInfo) {
      return false;
    }

    const n = new Date().getTime();
    return currentInfo.datetime < n && currentInfo.end_datetime > n;
  }, [currentInfo]);

  return (
    <Page id="tour" className={main}>
      <div className={info}>
        {currentInfo && (
          <div className={infoTitle}>
            <span>{duringDate ? "Currently in" : "Coming soon to"}</span>
            <span>{currentInfo.place}</span>
            <span>
              {currentInfo.formattedDateTime} -{" "}
              {currentInfo.formattedEndDateTime}
              <br />
              {currentInfo.venue}
            </span>
          </div>
        )}

        <div className={infoDescription}>
          <Markdown>{`Our bright yellow horsebox is on the road this summer,
designed to get the UK talking about how meat and dairy affects us and the environment,
and how we can all have a diet that is healthy and sustainable.
Check out our tour dates and come and say hello!`}</Markdown>
        </div>
      </div>
      <div className={map}>
        <div className={mapInner}>
          <MapSvg />

          {currentDates.map((d, idx) => (
            <div
              className={bubble}
              style={
                {
                  "--x": d.position.x,
                  "--y": d.position.y,
                  zIndex: Math.floor(d.position.y * 100),
                } as React.CSSProperties
              }
              key={d.datetime}
              // onClick={() => setSelected(idx)}
            >
              <div className={bubbleInfo}>
                {d.place}
                <br />
                {d.venue}
                <br />
                {d.formattedDateTime} - {d.formattedEndDateTime}
                <br />
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href={d.venue_google}
                >
                  Open maps
                </a>
              </div>
              {/* {BUBBLES[idx % BUBBLES.length]} */}
              <img src={MarkerImg} alt="Marker Pin" />
            </div>
          ))}
        </div>
      </div>
      <div className={datesCls}>
        <div className={box}>
          <NextSvg className={boxLogo} />
          {currentDates.map((d, idx) => (
            <div
              className={boxItem}
              key={d.datetime}
              // onClick={() => setSelected(idx)}
            >
              {d.place}
              <br />
              {d.formattedDateTime} - {d.formattedEndDateTime}
            </div>
          ))}

          {currentDates.length === 0 && (
            <div className={none}>
              <p>
                No dates scheduled right now, keep up to date on Social Media
                for when we announce more!
              </p>
              <p>
                <a
                  href="https://twitter.com/theliminalspace"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Twitter
                </a>
                <br />
                <a
                  href="https://www.instagram.com/theliminalspace/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Instagram
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

export { TourPage };
