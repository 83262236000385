import classNames from "classnames";
import React from "react";
import { Button } from "../../Button";
import { DecorativeCircle } from "../../DecorativeCircle";
import { Markdown } from "../../Markdown";

import { Page } from "../../Page";
import { Title } from "../../Title";

import {
  main,
  content,
  title,
  cta,
  circle,
  circleOne,
  circleTwo,
} from "./Support.module.scss";

const SupportPage: React.FC = () => {
  return (
    <Page id="support" className={main}>
      <div className={content}>
        <Title className={title}>Looking for more support?</Title>

        <Markdown>{`The LEAP researchers have developed a programme to help us all reduce our meat consumption. The programme lasts nine weeks and includes a week of self-monitoring your meat consumption, a four-week active meat reduction phase (with self-monitoring, goal setting, action planning and reflection), and a four week maintenance phase. All of this is delivered remotely through a bespoke website. Take part and you’re in with the chance of winning one of several £100 One4All gift cards!`}</Markdown>
        <Button
          className={cta}
          basic
          size="large"
          to="https://optimisediet.org/"
        >
          Join the Optimise Program
        </Button>

        <DecorativeCircle
          className={classNames(circle, circleOne)}
          pattern="003"
        />
        <DecorativeCircle
          className={classNames(circle, circleTwo)}
          pattern="005"
        />
      </div>
    </Page>
  );
};

export { SupportPage };
