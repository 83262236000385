// extracted by mini-css-extract-plugin
export var main = "Resources-module--main--32XbG";
export var content = "Resources-module--content--1bFN0";
export var pool = "Resources-module--pool--LbiH9";
export var filtered = "Resources-module--filtered--2Zn1K";
export var media = "Resources-module--media--12oVX";
export var item = "Resources-module--item--1ZP46";
export var large = "Resources-module--large--qr8hj";
export var small = "Resources-module--small--z8ESi";
export var thin = "Resources-module--thin--3blrm";
export var btn = "Resources-module--btn--155FJ";
export var cta = "Resources-module--cta--_nJ_g";
export var filterLink = "Resources-module--filter-link--1b_E6";
export var reset = "Resources-module--reset--27-dv";