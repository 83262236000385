import { GatsbyImageProps } from "gatsby-plugin-image";

export enum ItemType {
  Advice = "advice",
  Recipe = "recipe",
  Pledge = "pledge",
  Button = "button",
  Research = "research",
  Audio = "audio",
}

interface ItemCommon {
  // size: string;
}

export interface AudioItem extends ItemCommon {
  type: ItemType.Audio;
  title: string;
  audio: {
    publicURL: string;
  };
}

export interface AdviceItem extends ItemCommon {
  type: ItemType.Advice;
  image: GatsbyImageProps["image"];
}

export interface ResearchItem extends ItemCommon {
  type: ItemType.Research;
  image: GatsbyImageProps["image"];
}

export interface RecipeItem extends ItemCommon {
  type: ItemType.Recipe;
  image: GatsbyImageProps["image"];
  recipe: string;
}

export interface PledgeItem extends ItemCommon {
  type: ItemType.Pledge;
  image: GatsbyImageProps["image"];
}

export interface ButtonItem extends ItemCommon {
  type: ItemType.Button;
  text: string;
  to: string;
}

export type Item =
  | AdviceItem
  | RecipeItem
  | PledgeItem
  | ButtonItem
  | ResearchItem
  | AudioItem;

export interface PersonaResult {
  frontmatter: {
    title: string;
    tagline: string;
    recipe: string;
    facts: string[];
    image: GatsbyImageProps["image"];
    audio: {
      publicURL: string;
    };
  };
  fields: {
    persona: string;
  };
  rawMarkdownBody: string;
}
