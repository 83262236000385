import classNames from "classnames";
import React from "react";
import { DecorativeCircle } from "../../DecorativeCircle";
import { Page } from "../../Page";
import { Title } from "../../Title";

import {
  content,
  main,
  circle,
  circleOne,
  circleTwo,
  circleThree,
  title,
} from "./AboutLeap.module.scss";

const AboutLeap: React.FC = ({ children }) => {
  return (
    <Page id="about" className={main}>
      <div className={content}>
        <Title className={title}>About Meat Your Persona</Title>
        {children}
      </div>

      <div className={classNames(circle, circleOne)}>
        <DecorativeCircle pattern="004" />
      </div>
      <div className={classNames(circle, circleTwo)}>
        <DecorativeCircle pattern="005" />
      </div>
      <div className={classNames(circle, circleThree)}>
        <DecorativeCircle pattern="006" />
      </div>
    </Page>
  );
};

export { AboutLeap };
