import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { ReactComponent as BurgerSvg } from "../../images/burger.svg";
import { splash } from "./Splash.module.scss";

const Splash: React.FC = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 500);
  }, []);

  return (
    <AnimatePresence>
      {show && (
        <motion.div exit={{ opacity: 0 }} className={splash}>
          <BurgerSvg />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export { Splash };
