import React from "react";

import * as styles from "./Footer.module.scss";

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.inner}>
        <p>
          Meat Your Persona is a public engagement project created by design
          agency{" "}
          <a
            href="https://www.the-liminal-space.com/"
            target="_blank"
            rel="noreferrer noopener"
          >
            The Liminal Space
          </a>{" "}
          and{" "}
          <a
            href="https://www.leap.ox.ac.uk/"
            target="_blank"
            rel="noreferrer noopener"
          >
            LEAP
          </a>{" "}
          researchers at the{" "}
          <a
            href="https://www.ox.ac.uk/"
            target="_blank"
            rel="noreferrer noopener"
          >
            University of Oxford
          </a>
          . It aims to explore the impact of our eating habits, spark
          conversations and share information from LEAP researchers.
        </p>
        <h5 className={styles.smallTitle} id="privacy">
          Privacy
        </h5>
        <p className={styles.small}>
          This site uses cookies to anonymously gather analytics about how the
          site is used. For more information on the gathering on analytics,
          refer to{" "}
          <a
            href="https://policies.google.com/privacy?hl=en-US"
            target="_blank"
            rel="noreferrer noopener"
          >
            this link
          </a>
        </p>
      </div>
    </footer>
  );
};

export { Footer };
