// extracted by mini-css-extract-plugin
export var main = "Tour-module--main--28qpw";
export var map = "Tour-module--map--2FbUA";
export var mapInner = "Tour-module--map-inner--1xcTp";
export var info = "Tour-module--info--afcYZ";
export var infoTitle = "Tour-module--info-title--3MIwY";
export var infoDescription = "Tour-module--info-description--1CUkx";
export var bubble = "Tour-module--bubble--1LZKn";
export var bubbleInfo = "Tour-module--bubble-info--wznTn";
export var dates = "Tour-module--dates--3drH1";
export var box = "Tour-module--box--jaz6K";
export var boxLogo = "Tour-module--box-logo--zu3Oh";
export var boxItem = "Tour-module--box-item--3IrMX";
export var none = "Tour-module--none--3_7tF";