import React, { useEffect, useMemo } from "react";

import { Layout } from "../components/Layout";
import { SEO } from "../components/SEO";
import { navigate } from "gatsby";

import { registerBootlegVH } from "../utils/events";
import { TopPage } from "../components/Pages/Top";
import { TourPage } from "../components/Pages/Tour";

import { main } from "../styles/pages/Home.module.scss";
import { graphql, PageProps } from "gatsby";
import { mapRange } from "../utils/utils";
import { QuizPage } from "../components/Pages/Quiz";
import { AboutPage } from "../components/Pages/About";
import { ResourcesPage } from "../components/Pages/Resources";
import { AboutLeap } from "../components/Pages/AboutLeap";
import { Markdown } from "../components/Markdown";
import { Footer } from "../components/Pages/Footer";
import { Splash } from "../components/Splash";
import { Page } from "../components/Page";
import { Title } from "../components/Title";
import { SupportPage } from "../components/Pages/Support";

interface DataStruct {
  about: {
    rawMarkdownBody: string;
  };
  data: {
    edges: Array<{
      node: {
        frontmatter: {
          datetime: string;
          end_datetime: string;
          description?: string;
          location: string;
          place: string;
          venue: string;
          venue_google: string;
        };
      };
    }>;
  };
}

const IndexPage: React.FC<PageProps<DataStruct>> = ({ data }) => {
  const dates = useMemo(() => {
    return data.data.edges.map(({ node }) => {
      const location = JSON.parse(node.frontmatter.location);
      return {
        ...node.frontmatter,
        datetime: parseInt(node.frontmatter.datetime, 10) * 1000,
        end_datetime:
          parseInt(
            node.frontmatter.end_datetime || node.frontmatter.datetime,
            10,
          ) * 1000,
        location,
        position: {
          x: mapRange(
            location.coordinates[0],
            -7.3179245,
            1.0282516,
            0.05,
            0.95,
          ),
          y: mapRange(location.coordinates[1], 59.1620278, 49.7832306, 0.05, 1),
        },
      };
    });
  }, [data]);

  useEffect(() => {
    registerBootlegVH();
  }, []);

  useEffect(() => {
    if (window.location.search.includes("quiz")) {
      navigate("/quiz", {
        replace: true,
      });
    }
  }, []);

  return (
    <Layout className={main}>
      <SEO />

      <Splash />

      <TopPage />

      <AboutPage />

      <TourPage dates={dates} />

      <QuizPage />

      <ResourcesPage />

      <SupportPage />

      <AboutLeap>
        <Markdown>{data.about.rawMarkdownBody}</Markdown>
      </AboutLeap>

      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query DatesQuery {
    about: markdownRemark(fileAbsolutePath: { regex: "/about.md/" }) {
      rawMarkdownBody
    }
    data: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/dates/" } }
      sort: { fields: frontmatter___datetime, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            datetime
            end_datetime
            description
            location
            place
            venue
            venue_google
          }
        }
      }
    }
  }
`;

export default IndexPage;
