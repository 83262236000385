import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, GatsbyImageProps, getImage } from "gatsby-plugin-image";

import { Page } from "../../Page";
import { useSiteContext } from "../../SiteContext";
import { Title } from "../../Title";
import { AudioCircle } from "../../AudioCircle";
import { Button } from "../../Button";

// import RecipeImage from "../../../images/test-recipe.png";
// import RecipeImage1 from "../../../images/test-recipe1.png";
// import PledgeImage from "../../../images/test-pledge.png";
import { ReactComponent as CloseSvg } from "../../../images/svg/close.svg";

import {
  main,
  content,
  pool,
  item as itemCls,
  recipe,
  large,
  small,
  thin,
  btn,
  media,
  cta,
  filterLink,
  activeFilterLink,
  filtered,
  reset,
  invert,
} from "./Resources.module.scss";
import { AdviceItem, Item, ItemType, ResearchItem } from "../../../utils/types";
import { shuffle } from "../../../utils/utils";
import { sendEvent } from "../../../utils/analytics";

const ItemCls = {
  [ItemType.Advice]: large,
  [ItemType.Pledge]: small,
  [ItemType.Recipe]: large,
  [ItemType.Button]: thin,
  [ItemType.Research]: large,
  [ItemType.Audio]: large,
};

interface ResourcesQuery {
  resources: {
    frontmatter: {
      items: Item[];
    };
  };
}

interface ResourceItemProps {
  onSetRecipe: (recipe: string) => void;
  onSetItem: (item: AdviceItem | ResearchItem) => void;
  item: Item;
}

const ResourceItem: React.FC<ResourceItemProps> = ({
  item,
  onSetRecipe,
  onSetItem,
}) => {
  const cls = classNames(itemCls, [ItemCls[item.type]], {
    [recipe]: item.type === "recipe",
  });

  if (item.type === "recipe") {
    return (
      <div
        className={cls}
        onClick={() => {
          sendEvent("engagement", {
            type: "recipe resource",
          });
          onSetRecipe(item.recipe);
        }}
      >
        <GatsbyImage className={media} image={getImage(item.image)} alt="" />
      </div>
    );
  }

  if (item.type === "pledge") {
    return (
      <div className={cls}>
        <GatsbyImage className={media} image={getImage(item.image)} alt="" />
      </div>
    );
  }

  if (item.type === "advice" || item.type === "research") {
    return (
      <div
        className={cls}
        onClick={() => {
          sendEvent("engagement", {
            type: "advice resource",
          });
          onSetItem(item);
        }}
      >
        <GatsbyImage className={media} image={getImage(item.image)} alt="" />
      </div>
    );
  }

  if (item.type === "audio") {
    return (
      <div className={cls}>
        <AudioCircle audio={item.audio.publicURL} />
      </div>
    );
  }

  if (item.type === "button") {
    return (
      <div className={cls}>
        {item.text && (
          <Button basic size="large" to={item.to} className={btn}>
            {item.text}
          </Button>
        )}
      </div>
    );
  }

  return null;
};

const ResourcesPage: React.FC = () => {
  const { setRecipe, setActiveLightboxItem } = useSiteContext();

  const [activeFilter, setActiveFilter] = useState<ItemType>(null);
  const [page, setPage] = useState<number>(1);

  const data = useStaticQuery<ResourcesQuery>(graphql`
    query ResourcesQuery {
      resources: markdownRemark(fileAbsolutePath: { regex: "/resources.md/" }) {
        frontmatter {
          items {
            recipe
            audio {
              publicURL
            }
            title
            text
            to
            type
            filterType
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `);

  const onSetItem = useCallback(
    (item: AdviceItem) => {
      setActiveLightboxItem(item);
    },
    [data],
  );

  //mental logic, soz future me
  const foldedItems = useMemo(() => {
    const items = {
      recipe: shuffle([
        ...data.resources.frontmatter.items.filter(
          (item) => item.type === "recipe",
        ),
      ]),
      advice: shuffle([
        ...data.resources.frontmatter.items.filter(
          (item) => item.type === "advice",
        ),
      ]),
      pledge: shuffle([
        ...data.resources.frontmatter.items.filter(
          (item) => item.type === "pledge",
        ),
      ]),
      research: [
        ...data.resources.frontmatter.items.filter(
          (item) => item.type === "research",
        ),
      ],
      audio: [
        ...data.resources.frontmatter.items.filter(
          (item) => item.type === "audio",
        ),
      ],
      button: [
        ...data.resources.frontmatter.items.filter(
          (item) => item.type === "button",
        ),
      ],
    };

    const arr = [];
    let counter = 0;
    let individualCounters = {
      advice: 0,
      recipe: 0,
      pledge: 0,
      button: 0,
      audio: 0,
      research: 0,
    };

    const regular = ["advice", "recipe", "research", "audio"];

    while (
      individualCounters.advice < items.advice.length ||
      individualCounters.recipe < items.recipe.length ||
      individualCounters.research < items.research.length
    ) {
      const m = regular[counter % regular.length];

      if (arr.length === 6 && individualCounters.button === 0) {
        arr.push(items.button[individualCounters.button]);
        individualCounters.button++;
      }

      if (arr.length === 9 && individualCounters.button === 1) {
        arr.push(items.button[individualCounters.button]);
        individualCounters.button++;
      }

      if (items[m].length >= individualCounters[m]) {
        arr.push({ ...items[m][individualCounters[m]] });
        individualCounters[m]++;
        arr.push(items.pledge[individualCounters.pledge % items.pledge.length]);
        individualCounters.pledge++;
      }

      counter++;
    }

    return arr;
  }, [data]);

  const currentItems = useMemo(() => {
    const items = foldedItems
      .filter((item) => {
        if (!activeFilter) {
          return true;
        }

        return item.type === activeFilter || item.filterType === activeFilter;
      })
      .slice(0, page * 10);

    if (activeFilter) {
      const btnOne = foldedItems.find(
        (item) => item.type === "button" && item.text.includes("OPTIMISE"),
      );

      items.splice(4, 0, btnOne);
      return items;
    } else {
      return items;
    }
  }, [page, foldedItems, activeFilter]);

  const hasMore = useMemo(() => {
    return (
      foldedItems.filter((item) => {
        if (!activeFilter) {
          return true;
        }

        return item.type === activeFilter || item.filterType === activeFilter;
      }).length >
      page * 10
    );
  }, [page, foldedItems, activeFilter]);

  return (
    <Page id="resources" className={main}>
      <div className={content}>
        <Title>Hungry for more?</Title>
        <p>
          Browse our{" "}
          <span
            className={classNames(
              filterLink,
              { [activeFilterLink]: activeFilter === ItemType.Advice },
              "red",
            )}
            onClick={() => {
              sendEvent("engagement", {
                type: "filter",
              });
              setActiveFilter(
                activeFilter === ItemType.Advice ? null : ItemType.Advice,
              );
            }}
          >
            tips
          </span>{" "}
          to make your diet healthier and more sustainable, delicious meat-free{" "}
          <span
            className={classNames(
              filterLink,
              { [activeFilterLink]: activeFilter === ItemType.Recipe },
              "green",
            )}
            onClick={() => {
              sendEvent("engagement", {
                type: "filter",
              });
              setActiveFilter(
                activeFilter === ItemType.Recipe ? null : ItemType.Recipe,
              );
            }}
          >
            recipes
          </span>{" "}
          and{" "}
          <span
            className={classNames(
              filterLink,
              { [activeFilterLink]: activeFilter === ItemType.Research },
              "purple",
            )}
            onClick={() => {
              sendEvent("engagement", {
                type: "filter",
              });
              setActiveFilter(
                activeFilter === ItemType.Research ? null : ItemType.Research,
              );
            }}
          >
            insights
          </span>{" "}
          from the researchers at LEAP.
        </p>
      </div>
      <AnimatePresence exitBeforeEnter>
        {!!activeFilter && (
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ y: 50, opacity: 0 }}
            className={reset}
            onClick={() => setActiveFilter(null)}
          >
            View all <CloseSvg />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={`filter-${activeFilter}`}
          initial={{ y: 50, opacity: 0 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ y: 50, opacity: 0 }}
          className={classNames(pool, { [filtered]: !!activeFilter })}
        >
          {currentItems.map((ritem, idx) => (
            <ResourceItem
              key={`${ritem.type}-${idx}`}
              item={ritem}
              onSetRecipe={setRecipe}
              onSetItem={onSetItem}
            />
          ))}
        </motion.div>
      </AnimatePresence>
      {hasMore && (
        <div className={cta}>
          <Button
            size="large"
            basic
            invert
            className={classNames(btn, invert)}
            onClick={() => {
              sendEvent("engagement", {
                type: "more",
              });
              setPage((p) => p + 1);
            }}
          >
            Load more resources
          </Button>
        </div>
      )}
    </Page>
  );
};

export { ResourcesPage };
