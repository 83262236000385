import classNames from "classnames";
import React from "react";
import { DecorativeCircle } from "../../DecorativeCircle";

import { Page } from "../../Page";

import {
  main,
  content,
  circle,
  circleOne,
  circleTwo,
} from "./About.module.scss";

const AboutPage: React.FC = () => {
  return (
    <Page id="introabout" className={main}>
      <div className={content}>
        <p>
          Meat Your Persona is a travelling exhibition exploring how what we eat
          and drink - and the way we produce it - has a big impact on our health
          and our planet. It’s based on research from the LEAP project at the
          University of Oxford.
        </p>
        <p>
          <a className="red" href="#quiz">
            Take our quiz
          </a>{" "}
          to find out what kind of meat eater you are, see{" "}
          <a className="green" href="#tour">
            where in the UK
          </a>{" "}
          you can find us, or delve into{" "}
          <a className="purple" href="#resources">
            tips, recipes and insights
          </a>{" "}
          from the researchers behind the project.
        </p>
        <DecorativeCircle
          className={classNames(circle, circleOne)}
          pattern="004"
        />
        <DecorativeCircle
          className={classNames(circle, circleTwo)}
          pattern="001"
        />
      </div>
    </Page>
  );
};

export { AboutPage };
