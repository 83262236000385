import classNames from "classnames";
import React from "react";
import { Page } from "../../Page";

import {
  title,
  titleOne,
  titleTwo,
  titleThree,
  tagline,
  header,
} from "./Top.module.scss";

const TopPage: React.FC = () => {
  return (
    <Page id="top" className={header}>
      <div className={classNames(title, titleOne)}>Meat</div>
      <div className={classNames(title, titleTwo)}>Your</div>
      <div className={classNames(title, titleThree)}>
        Persona
        <div className={tagline}>
          <span>WHAT YOU EAT AND HOW</span>
          <span>IT CAN CHANGE THE WORLD</span>
        </div>
      </div>
    </Page>
  );
};

export { TopPage };
